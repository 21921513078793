

  .dayCell {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
  }
  
  .dayCell:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  /* Yellow background for start/end dates */
  .singleNightBlocked {
    background-color: #ffd666; /* Yellow */
    color: black;
    cursor: pointer; /* Indicates it's interactive */
    border: 2px solid #ffc107; /* Optional: Adds a border for emphasis */
  }
  
  .overlappingBlocked {
    background-color: purple; /* Purple */
    color: white;
    cursor: not-allowed; /* Indicates it's not interactive */
    /* No additional CSS changes as per your request */
  }
  
  /* Blocked Date Ranges Section */
  .blockedDateRanges {
    margin-top: 20px;
  }
  
  .blockedRange {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
  
  .unblockButton {
    background-color: #ff4d4f;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .unblockButton:hover {
    background-color: #ff7875;
  }
  
  /* Block Button */
  .blockButton {
    margin-top: 10px;
    background-color: #1890ff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .blockButton:hover {
    background-color: #40a9ff;
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 50px;
  }

  
  .form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.7rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .formLabel {
    grid-column: span 1;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .existingImagesWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .imageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .editPropertyImage {
    width: 100px;
    height: auto;
  }
  
  .formInput,
  .formTextarea {
    grid-column: span 1;
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .formButton {
    grid-column: span 2;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .formButton:hover {
    background-color: #0056b3;
  }

  .formAndSpinnerContainer {
    position: relative;
  }
  
  .spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5); 
    z-index: 1000;
  }