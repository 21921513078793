.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  padding-top: 50px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 2rem;
}

.iconTextWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5rem; /* Add a little space between each characteristic */
}

.characteristic {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem; /* Restores size */
  color: #1b4b73; /* Restores blue color */
  margin-left: 10px; /* To keep the space between the icon and the text */
  white-space: nowrap; /* Add this line */
}


.header h1 {
  font-size: 2rem;
  margin: 0;
  margin-bottom: 5px;
  margin-left: 10px;
  color: #4a4a4a;
  font-weight: 500;
}

.dayCell {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}

.dayCell:hover {
  background-color: rgba(0, 0, 0, 0.1);
}


.singleNightBlocked {
  background-color: #ffd666; /* Yellow background */
  color: black;
  cursor: pointer;
}

.header .price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1b4b73;
}

.content {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
}


.imageSection {
  width: 100%; /* Default to 100% width for mobile and smaller screens */
  margin: auto;
}

@media (min-width: 1024px) {
  .imageSection {
    max-width: 70%; /* Apply 80% width only for screens wider than 1024px */
  }

  .imageSection img {
    max-height: 600px;
    object-fit: contain;
  }
}

.bigIcon {
  font-size: 2.2rem;
  color: #1b4b73;
  margin-right: 10px;
  fill: none;
  width: 30px;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
}

.detailsContainer h1 {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 15px;
  white-space: nowrap;  /* Prevents the wrapping */
}

.detailsContainer p {
  margin: 0;
}

.description {
  width: 100%;
}

.description h2 {
  font-size: 1.5rem;
  margin-bottom: 0.1rem; /* space between header and paragraph */
  letter-spacing: 1px; /* a little space between letters */
}

.description p {
  font-size: 1.2rem; /* not too big, not too small */
  color: #4a4a4a; /* slightly darker than normal text for readability */
  line-height: 1.5; /* space between lines */
  text-align: justify; /* aligns the text both left and right */
}

@media (max-width: 992px) {
  .content {
    flex-direction: column;
    gap: 2rem; /* Bigger gap between image and details for readability */
  }

  .header h1 {
    font-size: 1.5rem;
  }

  .header .price {
    font-size: 1.2rem;
  }
  
  .container {
    padding: 1.5rem; /* A bit more padding to prevent content from touching the edges */
  }
}

.whatsappButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #25d366;
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 1rem;
  margin-top: 1rem;
  gap: 10px; /* This is the space between the icon and the text */
}

.whatsappButton:hover {
  background-color: #1ebe57;
}

.guestSelector {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-top: 15px;
  margin-bottom: 15px;
}

.guestType {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;
  max-width: 250px;
}

@media (min-width: 768px) {
  .guestSelector {
    width: 100%; /* Adjust the width as needed for larger screens */
    padding: 1rem; /* Increase padding for larger screens if necessary */
    max-width: 500px; /* Or any other max-width that suits your design */
  }

  .guestType {
    margin: 1rem 0; /* Optionally adjust spacing between guest types */
    max-width: 100%; /* Allow guestType to take full width of guestSelector */
  }

  .guestTypeLabel, .buttonsContainer {
    /* Adjust font sizes if necessary for better readability on larger screens */
    font-size: 1.4rem;
  }
}

.calendarContainer {
  display: flex;
  align-items: center;
  min-width: 0; 
  width: 286px;
}

.guestType span {
  font-size: 1.2rem;
  color: #4a4a4a;
}

.guestType button {
  background-color: #1b4b73;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  font-size: 1rem;
}

.guestTypeLabel {
  flex-basis: 40%; /* Adjust based on your layout; ensures label width is fixed */
  text-align: left; /* Aligns text to the left */
}

.buttonsContainer {
  display: flex;
  justify-content: space-around; /* Evenly spaces the buttons */
  flex-basis: 60%; /* Remaining space for buttons; adjust as needed */
}

.guestType button:hover {
  background-color: #163d57;
}

/* Adjustments for mobile responsiveness */
@media (max-width: 768px) {
  .guestSelector {
    width: 100%;
    box-sizing: border-box;
  }
}

.totalPrice {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: space-between; /* Adjust this as needed, e.g., 'flex-start' to align items to the left */
  gap: 10px; /* Optional: adds some space between the two elements */
}

.priceInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start of the flex container */
}

.totalPrice p {
  font-size: 2.0rem;
  color: #1b4b73;
  margin-right: 10px;
  fill: none;
}


.totalPrice h2 {
  font-size: 1.5rem;
  margin: 0;
  white-space: nowrap;  /* Prevents the wrapping */
}


.guestSelectorAndDateRangePicker h2 {
  font-size: 1.5rem;
  margin: 0;
  white-space: nowrap;  /* Prevents the wrapping */
}

.bookingDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem; /* Space between items */
  /*padding-left: 2rem;  Adjust as necessary to match your design */
}


.bookingDetailsContainer h2 {
  font-size: 1.5rem;
  margin: 0;
  white-space: nowrap;  /* Prevents the wrapping */
}

@media (min-width: 1024px) {
  .detailsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .propertyAndBookingDetails {
    display: flex;
    flex-direction: column;
  }
}

.reservarButton {
  background-color: #4CAF50; /* Green background */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.reservarButton:hover {
  background-color: #45a049; /* Darker green on hover */
}

.reservarButton:disabled {
  background-color: #cccccc; /* Gray when disabled */
  cursor: not-allowed;
}

@media (max-width: 1024px) {
  .calendarContainer {
    min-width: 250px; /* Adjust based on your layout needs */
  }}