.navbarLink {
    font-size: 1.1rem;
    font-weight: bold;
}


  @media (max-width: 992px) { /* Bootstrap's lg breakpoint */
    .navbarLink {
        font-size: 1.1rem;
        font-weight: bold;
        align-self: center;
    }
  }

