/* Basic styles */
.header-container {
    background-color: #333; /* Dark background */
    color: white; /* Light text */
    padding: 20px 0; /* Increase top and bottom padding */
  }
  
  .header-logo img {
    max-width: 100%;
    height: auto;
    cursor: pointer;
  }
  
  .header-contact {
    text-align: center;
  }
  
  .header-contact a {
    display: inline-block;
    margin: 0 5px;
    vertical-align: middle; /* Align items vertically */
    text-decoration: none; /* Removes underline from links */
  }
  
  /* Adjustments for medium devices (tablets) */
  @media (max-width: 992px) and (min-width: 768px) {
    .header-row {
      flex-direction: column; /* Stack logo and contact info vertically */
    }
  
    .header-logo, .header-contact {
      text-align: center;
      margin-bottom: 10px; /* Add some space between logo and contact info */
    }
  }

  /* Existing styles... */

/* Add styles for the social media icons */
.social-logo {
  margin: 0 5px; /* Space between icons */
  vertical-align: middle; /* Align with the text */
  width: 30px; /* Control size */
  height: auto;
}

/* Optional: Adjust hover effect */
.social-logo:hover {
  opacity: 0.8;
}

/* Adjust .header-contact to wrap content if needed */
.header-contact {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.header-contact a {
  display: inline-flex;
  align-items: center;
  margin: 5px;
  text-decoration: none;
}

/* For smaller screens */
@media (max-width: 767px) {
  .header-contact a {
    font-size: 0.9rem;
    display: block;
    margin: 5px auto;
  }
}

  
  /* Fine-tune adjustments for smaller devices */
  @media (max-width: 767px) {
    .header-contact a {
      font-size: 0.9rem; /* Adjust font size for smaller screens */
      display: block; /* Stack links vertically */
      margin: 5px auto; /* Center align the links */
    }
  }

  .email-logo, .whatsapp-logo {
    margin-right: 5px; /* Space between icon and text */
    vertical-align: middle; /* Align with the text */
    width: 30px; /* Control size */
    height: auto;
  }

  
  