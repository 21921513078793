.pointer {
  cursor: pointer;
}
.pointer:hover {
  opacity: 0.7;
}
.accordion-button {
  background-color: rgb(216, 214, 214) !important;
  color: #000000;
}
.accordion-button:not(.collapsed) {
  color: #000000;
}
.accordion-button:focus {
  border-color: #000000;
  box-shadow: #000000;
}
